import React, {Fragment, useEffect, useRef, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import axios from "axios";

import Modal from "../Modal";
import firebase from "firebase";
import Layout from "../layout";
import SEO from "../seo";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import {getFirebase, getUiConfig, uiConfig} from "../../utils/signin";
import {getUserLimits} from "../../pages/account";
import {navigate} from "gatsby";


// const slazzerK = 'b4ef1c6729564392be579e003f14e716';
const slazzerSettings = {
    url: "https://api.slazzer.com/v2.0/remove_image_background",
    apiKey: "b4ef1c6729564392be579e003f14e716",
    outputImagePath: "output.png"
};

const DownloadProductImageModal = ({isOpen = false, setIsOpen, imageUrl, setIsLoadingMutation}) => {
    const [imagesQuota, setImagesQuota] = useState(0);
    const [limits, setLimits] = useState({});
    const [downloaded, setDownloaded] = useState(0);
    const firebase = getFirebase();
    useEffect(() => {
        if (!firebase) return;

        const unregisterAuthObserver = getFirebase().auth().onAuthStateChanged(async user => {
            setImagesQuota(await getImagesToDownload());
        });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);
    const handleDownloadNormally = e => {
        const urlSplited = imageUrl.split('/');
        const filename = urlSplited.length > 0 ? urlSplited[urlSplited.length - 1] : 'deerhome.png';
        fetch(imageUrl, {
            method: "GET",
            headers: {}
        })
            .then(response => {
                response.arrayBuffer().then(function (buffer) {
                    const url = window.URL.createObjectURL(new Blob([buffer]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", filename);
                    document.body.appendChild(link);
                    link.click();
                });
            })
            .catch(err => {
                console.log(err);
            });
    };
    const getImagesToDownload = async () => {
        const userId = getFirebase().auth().currentUser?.uid;
        if (!userId) throw 'error';

        const firestoreDb = getFirebase().firestore();
        const collectionRef = firestoreDb.collection("customers");
        const monthId = getMonthId();
        const customerImagesStatsForMonth = await collectionRef.doc(userId).collection("images_stats").doc(monthId).get();
        const downloaded = customerImagesStatsForMonth.data()?.downloaded || 0;
        const limits = await getUserLimits(userId);
        setLimits(limits);
        setDownloaded(downloaded);
        return limits.imagesQuota - downloaded;
    }
    const getMonthId = () => {
        const date = new Date();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${month}-${year}`;
    }
    const handleUpdateImageDownload = async () => {
        const userId = getFirebase().auth().currentUser?.uid;
        if (!userId) return 'error';

        const monthId = getMonthId();
        const firestoreDb = getFirebase().firestore();
        const collectionRef = firestoreDb.collection("customers");
        await collectionRef.doc(userId).collection("images_stats").doc(monthId).set({
            downloaded: getFirebase().firestore.FieldValue.increment(1),
        }, {merge: true});
    }

    const handleRemoveBackground = async () => {
        try {
            if (imagesQuota <= 0) return;
            setIsLoadingMutation(true);
            const form = new FormData();
            form.append('source_image_url', imageUrl);
            form.append('preview', true);
            const resp = await axios.post(
                slazzerSettings.url,
                form,
                {
                    responseType: 'blob',
                    headers: {"API-KEY": slazzerSettings.apiKey}
                }
            );
            console.log('resp', resp);
            const url = window.URL.createObjectURL(new Blob([resp.data]));
            const urlSplited = imageUrl.split('/');
            const filename = urlSplited.length > 0 ? urlSplited[urlSplited.length - 1] : 'deerhome.png';
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            setIsLoadingMutation(false);
            handleUpdateImageDownload();
        } catch (error) {
            console.log('removeBackground error', error);
            setIsLoadingMutation(false);
        }
    }

    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
            <div
                className="inline-block w-full max-w-md my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="relative  sm:max-w-xl sm:mx-auto">
                    <div className="relative px-4 py-10 bg-white mx-8 md:mx-0 shadow rounded-3xl sm:p-10">
                        <div className="max-w-md mx-auto">
                            <div className="flex items-center space-x-5">
                                <div
                                    className="h-14 w-14 bg-blue-200 rounded-full flex flex-shrink-0 justify-center items-center text-blue-500 text-2xl font-mono">i
                                </div>
                                <div className="block pl-2 font-semibold text-xl self-start text-gray-700">
                                    <h2 className="leading-relaxed">Enregistrer cette image</h2>
                                    <p className="text-sm text-gray-500 font-normal leading-relaxed">Attention, appuyez
                                        sur le bouton "Enregistrer avec fond transparent" que si le fond de l'image est
                                        déjà claire, sinon le résultat ne peut-être garantie.</p>
                                    {imagesQuota <= 0 && (
                                        <div
                                            className="my-4 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded"
                                            role="alert">
                                            <div className='mr-10 text-sm'>
                                                <strong className="font-bold">Erreur, </strong>
                                                <span className="block sm:inline">Vous avez atteint le nombre maximum mensuel d'images à télécharger sans fond.</span>
                                            </div>
                                            <div className='text-center'>
                                                <button
                                                    className="bg-blue-500 text-white rounded p-2 ml-4 mt-2 transform hover:scale-110"
                                                    onClick={() => navigate('/#pricing')}>Changer d'offre 🚀
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="pt-6 flex items-center space-x-4">
                                <button
                                    download
                                    onClick={handleDownloadNormally}
                                    className="bg-gray-800 flex justify-center items-center w-full text-white px-4 py-3 rounded-md focus:outline-none">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                                         viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                              d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"/>
                                    </svg>
                                    Enregistrer tel quel
                                </button>
                                <button
                                    download
                                    disabled={imagesQuota <= 0}
                                    onClick={handleRemoveBackground}
                                    className="bg-blue-300 flex justify-center items-center w-full text-white px-4 py-3 rounded-md focus:outline-none">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none"
                                         viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                              d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z"/>
                                    </svg>
                                    Enregistrer avec fond transparent ({downloaded}/{limits.imagesQuota})
                                </button>
                            </div>
                            <div className="pt-6 flex items-start space-x-4">
                                <button
                                    onClick={() => setIsOpen(false)}
                                    type="reset"
                                    className="flex justify-start items-center w-full text-gray-900 px-4 py-3 rounded-md focus:outline-none">
                                    <svg className="w-6 h-6 mr-3" fill="none" stroke="currentColor"
                                         viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                              d="M6 18L18 6M6 6l12 12"/>
                                    </svg>
                                    Annuler
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default DownloadProductImageModal;
