// Import FirebaseAuth and getFirebase().
import React, {useEffect, useState} from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';
import {navigate} from "gatsby";
import * as queryString from "query-string";

import Signedlayout from "../components/Signedlayout";
import {getStringPriceFromCents} from "./my-products";
import DeleteProductModal from "../components/DeleteProductModal";
import DownloadProductImageModal from "../components/DownloadProductImageModal";
import {getFirebase} from "../utils/signin";

function MyProduct({location}) {
    const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.
    const [myProduct, setMyProduct] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [showCopiedUrl, setShowCopiedUrl] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [isLoadingMutation, setIsLoadingMutation] = useState(false);
    const [isOpenDeleteProductModal, setIsOpenDeleteProductModal] = useState(false);
    const [isOpenDownloadImageModal, setIsOpenDownloadImageModal] = useState(false);
    const [showError, setShowError] = useState(false);
    useEffect(() => {
        const unregisterAuthObserver = getFirebase().auth().onAuthStateChanged(user => {
            setIsSignedIn(!!user);
            const {productId} = queryString.parse(location.search);
            const db = getFirebase().firestore();
            const productsRef = db.collection('products');
            productsRef
                .doc(productId)
                .get().then((doc) => {
                console.log('querySnapshot', user.uid, productId, doc.exists, doc.data())
                if (!doc.exists) {
                    return;//error;
                }

                const {
                    title,
                    reference,
                    price,
                    url,
                    images,
                    tags,
                    dimension,
                    myDescription
                } = doc.data();
                setMyProduct({
                    id: productId,
                    title,
                    reference,
                    price: getStringPriceFromCents(price),
                    url,
                    images,
                    tags,
                    dimension,
                    myDescription
                });
                setSelectedImage(images[0]);
                setIsLoading(false);
            });
        });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);
    const {
        id,
        title,
        reference = 'N/A',
        price,
        url,
        images = [],
        tags,
        dimension = 'N/A',
        myDescription = 'N/A'
    } = myProduct || {};
    const handleOpenProduct = () => {
        window.open(url)
    }
    const handleCopyUrl = () => {
        setShowCopiedUrl(true);
        navigator.clipboard.writeText(url);
        setTimeout(() => {
            setShowCopiedUrl(false);
        }, 1000);
    }
    const handleFilterMyProductsWithFilter = (tag) => {
        navigate(`/my-products?tag=${tag}`)
    }
    const handleSelectImage = (imgUrl) => {
        setSelectedImage(imgUrl);
    }
    const handleRemoveProduct = async (event) => {
        event.preventDefault();
        try {
            setIsLoadingMutation(true);
            const db = getFirebase().firestore();
            const productsRef = db.collection('products');
            await productsRef
                .doc(id)
                .delete();
            setIsLoadingMutation(false);
            setIsOpenDeleteProductModal(false);
            navigate('/my-products');
        } catch (e) {
            handleShowError();
        }
    }
    const handleOpenCautionRemoveProduct = async (event) => {
        event.preventDefault();
        setIsOpenDeleteProductModal(true);
    }
    const handleOpenDownloadImage = async (event) => {
        event.preventDefault();
        setIsOpenDownloadImageModal(true);
    }
    const handleShowError = () => {
        setShowError(true);
        setIsLoadingMutation(false);
        setTimeout(() => {
            setShowError(false);
        }, 3000);
    }

    return (
        <Signedlayout>
            {isLoadingMutation && (
                <div
                    className="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50 flex items-center justify-center">
                    <svg className="animate-spin ml-1 mr-3 h-28 w-28 text-blue-700"
                         xmlns="http://www.w3.org/2000/svg"
                         fill="none"
                         viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                stroke-width="4"></circle>
                        <path className="opacity-75" fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </div>
            )}
            <div className="pt-24 pb-24">
                <div className="container px-3 mx-auto">
                    {isLoading ? (
                        <div className=" p-4 max-w-2xl w-full mx-auto">
                            <div className="animate-pulse flex space-x-40">
                                <div className="gradient h-96 w-80"></div>
                                <div className="flex-1 space-y-4 py-1">
                                    <div className="h-4 gradient rounded w-60"></div>
                                    <div className="space-y-2">
                                        <div className="h-5 gradient rounded"></div>
                                        <div className="h-5 gradient rounded w-5/6"></div>
                                    </div>
                                    <div className="h-4 gradient rounded w-60"></div>
                                    <div className="space-y-2">
                                        <div className="h-4 gradient rounded"></div>
                                        <div className="h-4 gradient rounded w-5/6"></div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="md:flex md:items-center">
                            <div className="md:flex md:items-center md:flex-col">
                                <div className=" relative w-full h-64 md:w-1/2 lg:h-96">
                                    <img className="h-full w-full rounded-md object-contain max-w-lg mx-auto"
                                         src={selectedImage}
                                         alt={title}/>
                                    <div className='absolute top-0 right-0'>
                                        <button onClick={handleOpenDownloadImage} className=' bg-black opacity-75 rounded p-1 transform hover:scale-105'>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-blue-300" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                <div
                                    className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 mt-6">
                                    {images.map((url, i) => (
                                        <button onClick={() => handleSelectImage(url)}>
                                            <div className='h-28 border rounded-md'>
                                                <img
                                                    className="h-full w-full rounded-md object-contain max-w-lg mx-auto"
                                                    src={url}
                                                    alt={`${title}-img-${i}`}/>
                                            </div>
                                        </button>
                                    ))}
                                </div>
                            </div>
                            <div className="w-full max-w-lg mx-auto mt-5 md:ml-8 md:mt-0 md:w-1/2">
                                <div className='flex justify-between'>
                                    <div>
                                        <h3 className="text-gray-700 uppercase text-lg">{title}</h3>
                                        <span className="text-gray-500 mt-3">{price} €</span>
                                    </div>
                                    <div>
                                        <button onClick={handleOpenCautionRemoveProduct}>
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 className="h-6 w-6 text-red-400 ml-4"
                                                 fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                <hr className="my-3"/>
                                <div className="mt-2">
                                    <label className="text-blue-900 text-sm"
                                           htmlFor="count">Réference/SKU:</label>
                                    <div className="flex items-center mt-1">
                                        <span className="text-gray-700 ">{reference}</span>
                                    </div>
                                </div>
                                <div className="mt-2">
                                    <label className="text-blue-900 text-sm" htmlFor="count">Dimensions:</label>
                                    <div className="flex items-center mt-1">
                                        <span className="text-gray-700 ">{dimension}</span>
                                    </div>
                                </div>
                                <div className="mt-2">
                                    <label className="text-blue-900 text-sm" htmlFor="count">Mes
                                        commentaires:</label>
                                    <div className="flex items-center mt-1">
                                        <span className="text-gray-700 ">{myDescription}</span>
                                    </div>
                                </div>

                                <div className="mt-2">
                                    <label className="text-blue-900 text-sm" htmlFor="count">Mes
                                        tags:</label>
                                    <div className='flex flex-wrap mt-1 mb-4'>
                                        {tags.map((tag) => (
                                            <button
                                                onClick={() => handleFilterMyProductsWithFilter(tag)}
                                                className="rounded ml-2 bg-blue-400 hover:bg-blue-500 p-1 pl-3 pr-3">
                                                <span className="text-white ">{tag}</span>
                                            </button>
                                        ))}
                                    </div>
                                </div>
                                <div className="mt-2"/>
                                <div className="mt-2">
                                    <label className="text-blue-900 text-sm flex align-middle" htmlFor="count">
                                        URL:
                                        <button onClick={handleCopyUrl}>
                                            <svg className="h-5 w-5 text-blue-500 ml-1" fill="none"
                                                 viewBox="0 0 24 24"
                                                 stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                      stroke-width="2"
                                                      d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"/>
                                            </svg>
                                        </button>
                                    </label>
                                    {showCopiedUrl && (
                                        <div className="mt-2">
                                                <span
                                                    className="text-blue-700 text-sm bg-blue-50 p-2 rounded">URL copiée !</span>
                                        </div>
                                    )}
                                    <div className="mt-1">
                                        <span className="text-gray-500 text-xs">{url}</span>
                                    </div>
                                </div>
                                <div className="mt-6 flex justify-center">
                                    <button
                                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded"
                                        onClick={handleOpenProduct}
                                    >
                                        Ouvrir le produit
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <DeleteProductModal handleRemove={handleRemoveProduct} isOpen={isOpenDeleteProductModal}
                                setIsOpen={setIsOpenDeleteProductModal}/>
            <DownloadProductImageModal
                isOpen={isOpenDownloadImageModal}
                setIsOpen={setIsOpenDownloadImageModal}
                imageUrl={selectedImage}
                setIsLoadingMutation={setIsLoadingMutation}
                // quota={quota}
            />
            {showError && (
                <div
                    className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded fixed bottom-6 w-2/6 left-0 right-0 ml-auto mr-auto"
                    role="alert">
                    <strong className="font-bold">Erreur! </strong>
                    <span className="block sm:inline">Une erreur est survenue, veuillez réessayer plus tard.</span>
                    <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                        <svg className="fill-current h-6 w-6 text-red-500" role="button"
                             xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 20 20"><title>Close</title><path
                            d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
                      </span>
                </div>
            )}
        </Signedlayout>
    );
}

export default MyProduct;